import { ActivatedRouteSnapshot, Resolve, UrlSegment } from '@angular/router';
import { BreadcrumbItem } from '@lobos/common-v3';
import { Observable, of } from 'rxjs';

export class BreadcrumbsResolver implements Resolve<BreadcrumbItem[]> {
  public resolve(route: ActivatedRouteSnapshot): Observable<BreadcrumbItem[]> {
    const data = route.data;

    if (!data) {
      return of([]);
    }

    const path = this.getFullPath(route);

    return of([{ label: data?.['breadcrumbs'], translate: data?.['translate'], url: this.removeLanguageCode(path) }]);
  }

  public getFullPath(route: ActivatedRouteSnapshot): string {
    return this.fetchFullPath(route.pathFromRoot);
  }

  private fetchFullPath(routes: ActivatedRouteSnapshot[]): string {
    return routes.reduce((path, route) => path + this.fetchRelativePath(route.url), '');
  }

  private fetchRelativePath(urlSegments: UrlSegment[]): string {
    return urlSegments.reduce((path, urlSegment) => path + '/' + urlSegment.path, '');
  }

  private removeLanguageCode(url: string): string {
    // Remove leading slash if present
    const trimmedUrl = url.startsWith('/') ? url.slice(1) : url;

    const parts = trimmedUrl.split('/', 2);
    if (parts.length > 1 && parts[0].length === 2) {
      const remainingPath = trimmedUrl.slice(parts[0].length + 1);
      return url.startsWith('/') ? `/${remainingPath}` : remainingPath;
    }
    return url;
  }
}
